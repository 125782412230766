/* eslint-disable react/no-unknown-property */
// "start": "webpack-dev-server --host 0.0.0.0 --port 15020 --progress --hot --open"

import React from "react";
import ReactDOM from "react-dom";
import { GeoJsonLayer } from "deck.gl";
import { TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer, PathLayer } from '@deck.gl/layers';
import $ from 'jquery';
import { DeckComponent, changeViewState, setCoordinates } from "./Deck/DeckComponent.jsx";
import { startTimeControl } from "./Funcionalidades/TimeControl/timeControl.jsx";
import { RightButton } from "./Funcionalidades/RightButton/RightButton.jsx";
import LoadingScreen, { Loading } from "./Loading/LoadingScreen.jsx";
import {
  getidentifier, getIdentifierId, getLat, getLng,
  getModeMap, getOpacidade, getOrientation, getZoomMap,
  setidentifier, setIdentifierId, setLat, setLng, setModeMap, setOpacidade, setZoomMap
} from "./DataHandler/DataHandler.jsx";
import { prepareColors, prepareDataIA } from "./Funcionalidades/PrepareDatas/prepareFunctions";
import * as turf from '@turf/turf';
import { firstFilter, MapControl } from "./Funcionalidades/MapControl/MapControl.jsx";
import WorkerBuilder from "./Funcionalidades/Worker/WorkerBuilder.jsx";
import { WorkerTime, WorkerMain } from "./Funcionalidades/Worker/Worker.jsx";
import { TitleInfo } from "./Funcionalidades/PopupInfo/TitleInfo.jsx";
import { distributeAlgorithms, distributeAlgorithmsDistributed, distributeAlgorithmsIA } from "./Funcionalidades/PrepareDatas/distributeAlgorithms.jsx";
import axios from "axios";
import { SidebarGeovista, StartSidebar } from "sidebargeovista";
import { generateHTML } from "./Funcionalidades/PrepareDatas/generateHTML.jsx";
import "./style.css"
import { setList, setModo } from "./Funcionalidades/Filter/Filter.jsx";
import { finishAnimation, SplashScreen, startSplashScreen } from "./Loading/SplashScreen.jsx";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';
import { downloadCsv } from "./Funcionalidades/PrepareDatas/formattedCsv.jsx";
import { setTypeFilter } from "./Funcionalidades/PrepareDatas/ChartRug.jsx";

window.onload = function () {
  const hash = window.location.hash; // This gets everything after the #
  const params = new URLSearchParams(hash.substring(1)); // Remove the "#" and parse
  const token = params.get('id_token');

  if (token) {
    validateToken(token)
      .then(isValid => {
        if (isValid) {
          console.log("Token is valid, continue on the page.");
        } else {
          // window.location.href = 'https://d1e9ehs11bdorj.cloudfront.net/';
        }
      })
      .catch(error => {
        console.error('Error validating token:', error);
        // window.location.href = 'https://d1e9ehs11bdorj.cloudfront.net/';
      });
  } else {
    // window.location.href = 'https://d1e9ehs11bdorj.cloudfront.net/';
  }
};

async function validateToken(token) {
  try {
    const response = await fetch('https://dfet16y6vi.execute-api.sa-east-1.amazonaws.com/prod/mss-auth/get-user', {
      method: 'POST',
      mode: 'no-cors', // Bypass CORS (response will be opaque)
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      console.log('response', response)
      throw new Error('Failed to validate token');
    }

    const data = await response.json();
    if (data['system_name'].includes('RUGOSIDADE')) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Validation error:', error);
    return false;
  }
}


Chart.register(...registerables);
const instanceTime = new WorkerBuilder(WorkerTime);
const instanceMain = new WorkerBuilder(WorkerMain);

let APIcidade
if ("{{{payload.suaapi}}}".includes("{payload.suaapi}")) {
  APIcidade = "https://dev.geovista.com.br/api/ruas/js/curitiba/parametros.json"
} else {
  APIcidade = "{{{payload.suaapi}}}"
}

// Variáveis necessárias no Deck.gl
var jsonData, FUNDO, IA, distritosDeck, predefinicoes, extensaoruas,
  dinamicos, fixos, fundoDeck, openPage, htmlSidebar, apianalisefiltros,
  // htmlSidebarCadastro, htmlSidebarAnalises, htmlFilterSidebar, 
  IASeparator;
var polygon = []
var polygonIAAtiv = []
var polygonIAEnc = []
var polygonIANull = []
var polygonIASELECTEDAtiv = []
var polygonIASELECTEDEnc = []
var polygonIASELECTEDNull = []
var DATA = []
var DATAIA = []
var FRACTURE = []
var FRACTUREAtiv = []
var FRACTUREEnc = []
var FRACTURENull = []
var DATAIASELECTED = []
var DATAIASELECTEDAtiv = []
var DATAIASELECTEDEnc = []
var DATAIASELECTEDNull = []
var DATAAUX = []
var DATASELECTED = []
var selectedStreetArray = []
var selectedGeometryArray = []
var analisesArray = []
var RelatorioPolylineDeck = []
let list = []
var selectedStreet = {
  "name": "layer",
  "type": "FeatureCollection",
  "features": []
}
var selectedGeometry = turf.clone(selectedStreet)
function setTilesMapStyle(both) {
  const tilesMapStyle = both
    ? "https://c.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
    : "https://c.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png";
  TILES = createTileLayer(tilesMapStyle);
}

function createTileLayer(data) {
  return new TileLayer({
    data,
    maxRequests: 20,
    pickable: true,
    onViewportLoad: null,
    autoHighlight: false,
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    renderSubLayers: props => {
      const {
        bbox: { west, south, east, north }
      } = props.tile;

      return [
        new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [west, south, east, north]
        }),
        new PathLayer({
          id: `${props.id}-border`,
          visible: props.visible,
          data: [
            [
              [west, north],
              [west, south],
              [east, south],
              [east, north],
              [west, north]
            ]
          ],
          getPath: d => d,
          getColor: [0, 0, 0, 0],
          widthMinPixels: 0,
        })
      ];
    }
  });
}

var TILES = createTileLayer("https://c.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png");
// Variáveis necessárias para controle de JSON

var tituloLegenda = "qualidade"
var hexColorJson = "hexCor"

export function ChartModel(props) {
  let html, data

  const calculatePadding = () => {
    if (props.mode.toLowerCase() !== "doughnut") {
      if (props.orientation === "x") {
        return {
          left: 0,
          right: 0,
          top: 20,
          bottom: 0
        }
      } else {
        return {
          left: 0,
          right: 60,
          top: 5,
          bottom: 0
        }
      }
    } else {
      return {
        left: 0,
        right: 0,
        top: 10,
        bottom: 0,
      }
    }
  };

  var options = {
    indexAxis: props.orientation === "x" ? "x" : "y",
    layout: {
      padding: calculatePadding
    },
    scales: {
      x: {
        display: props.orientation === "x" ? true : false,
        stacked: props.stacked ? true : false,
        max: props.stacked ? 100 : undefined,
        beginAtZero: props.stacked ? true : false
      },
      y: {
        display: props.stacked ? true : false,
        stacked: props.stacked ? true : false,
        beginAtZero: props.stacked ? true : false
      },
    },
    rotation: function () {
      if (props.half === true) { return -90 } else { return 0 }
    },
    circumference: function () { if (props.half === true) { return 180 } else { return 360 } },
    // cutoutPercentage: function () { if (props.half === true) { return 70 } },
    animation: {
      duration: 0,
      onComplete: function (chart) {
        if (props.half === true) {
          const values = chart.chart.data.datasets[0].data
          const percentage = ((values[0] / (values[0] + values[1])) * 100).toFixed(2) + "%";
          const centerX = chart.chart.canvas.clientWidth / 2;
          const centerY = chart.chart.canvas.clientHeight * (1 - 0.1);
          const text = percentage;

          // Font settings
          const ctx = chart.chart.ctx;
          ctx.font = '17px Montserrat';
          ctx.fontWeight = 'bold';
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          ctx.fillText(text, centerX, centerY);
        }
      }
    },
    plugins: {
      datalabels: {
        formatter: function (value, ctx) {
          if (props.half !== true) {
            if (props.mode.toLowerCase() !== "bar") {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => { sum += data });
              let percentage = (value * 100 / sum).toFixed(0) + "%";
              return percentage;
            } else {
              if (props.stacked) { return value + "%" }
              else { return value }
            }
          } else {
            return ""
          }
        },
        display: function (ctx) {
          if (props.mode.toLowerCase() !== "bar") {
            let value = ctx.dataset.data[ctx.dataIndex];
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => { sum += data });
            let percentage = (value * 100 / sum).toFixed(0) + "%";
            return (percentage !== "0%") ? true : false
          }
        },
        color: 'white',
        backgroundColor: function (ctx) {
          if (!props.stacked) {
            return ctx.dataset.backgroundColor[ctx.dataIndex];
          }
        },
        borderWidth: function (ctx) {
          if (props.mode.toLowerCase() !== "bar") {
            let value = ctx.dataset.data[ctx.dataIndex];
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => { sum += data });
            let percentage = (value * 100 / sum).toFixed(0);
            return (percentage <= 4) ? 1 : 0
          } else {
            return 1
          }
        },
        borderColor: function (ctx) {
          if (props.mode.toLowerCase() !== "bar") {
            let value = ctx.dataset.data[ctx.dataIndex];
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => { sum += data });
            let percentage = (value * 100 / sum).toFixed(0);
            return (percentage <= 5) ? '#fff' : 'transparent'
          } else {
            if (props.stacked) { return 'transparent' }
            else { return '#fff' }
          }
        },
        borderRadius: 20,
        anchor: (props.mode.toLowerCase() !== "bar") ? "center" : (props.stacked) ? "center" : "end",
        align: (props.mode.toLowerCase() !== "bar") ? "center" : (props.stacked) ? "center" : "end",
        padding: {
          top: 3,
          right: 3,
          bottom: 1,
          left: 3,
        },
        offset: 0,
        font: {
          weight: 'bold',
          size: 11
        },
      },
      tooltip: (!props.half && !props.stacked) ? {
        backgroundColor: function (context) {
          if (context.tooltipItems.length !== 0) {
            const ctx = context.tooltipItems[0]
            return ctx.dataset.backgroundColor[ctx.dataIndex];
          }
        },
        titleFont: {
          weight: 'bold',
          size: 12
        },
        bodyFont: {
          weight: 'bold',
          size: 10
        },
        titleAlign: 'center',
        bodyAlign: 'center',
        borderWidth: 1,
        borderColor: '#fff',
        displayColors: false,
        callbacks: {
          title: (context) => {
            return `#${context[0].label}`;
          },
          label: function (context) {
            var value = context.formattedValue
            if (props.stacked) {
              return value + "%";
            } else {
              return (props.counter !== undefined) ? value + props.counter : value;
            }
          }
        }
      } : {
        enabled: false
      },
      legend: {
        display: (props.mode.toLowerCase() !== "bar") ? props.legend : false,
        position: "left",
        align: "center",
        textDirection: 'ltr',
        labels: {
          usePointStyle: true,
        }
      },
      title: {
        display: () => {
          if (props.title !== undefined) {
            return true
          } else {
            return false
          }
        },
        text: props.stacked ? props.title : props.title + "%",
      }
    },
    responsive: () => {
      if (props.responsive === true) { return true }
      else { return false }
    },
    maintainAspectRatio: () => {
      if (props.mode.toLowerCase() !== "bar") {
        if (props.responsive === true) { return true }
        else { return false }
      } else {
        if (props.stacked) { return true }
        else { return false }
      }
    },
    // eslint-disable-next-line no-unused-vars
    onClick: (e, element) => {
      // console.log(e, element)
    },
  };

  data = {
    labels: props.labels,
    datasets: [
      {
        backgroundColor: props.colors,
        borderWidth: 1,
        borderRadius: 5,
        data: props.datas,
        barPercantage: 1,
        categoryPercentage: 1,
        barThickness: 'flex',
      }
    ]
  };

  const generateDatasets = () => {
    return props.datas.map((data, index) => ({
      label: `Segment ${index + 1}`,
      data: data,
      backgroundColor: props.colors[index],
      categoryPercentage: 0.9,
      barPercentage: 1,
    }));
  };

  if (props.stacked) {
    data = {
      labels: props.labels,
      datasets: generateDatasets()
    };
  }

  if (props.mode.toLowerCase() === "pie") {
    html = (
      <Pie
        data={data} options={options} height={props.height} width={props.width} plugins={[ChartDataLabels]}
      />
    )
  } else if (props.mode.toLowerCase() === "bar") {
    html = (
      <Bar
        data={data} options={options} height={props.height} width={props.width} plugins={[ChartDataLabels]}
      />
    )
  } else if (props.mode.toLowerCase() === "doughnut") {
    html = (
      <Doughnut
        data={data} options={options} height={props.height} width={props.width} plugins={[ChartDataLabels]}
      />
    )
  }

  return (
    <div >
      {html}
    </div>
  );
}

export function getRequest(url) {
  return new Promise(resolve => {
    $.ajax({
      type: "GET",
      url: url,
      dataType: 'JSON',
      success: function (json) {
        resolve(json)
      }
    })
  });
}

export function desativarLayer(titulo, mode) {
  try {
    for (let i = 0; i < DATA.length; i++) {
      for (let j = 0; j < DATA[i].features.length; j++) {
        if (titulo === DATA[i].features[j].properties[tituloLegenda]) {
          DATA[i].features[j].properties.visibility = !DATA[i].features[j].properties.visibility
        }
      }
    }

    if (mode === true) {
      for (let i = 0; i < DATAIASELECTEDAtiv.length; i++) {
        for (let j = 0; j < DATAIASELECTEDAtiv[i].features.length; j++) {
          if (titulo === DATAIASELECTEDAtiv[i].features[j].properties.ai_object_name) {
            DATAIASELECTEDAtiv[i].features[j].properties.visibility = !DATAIASELECTEDAtiv[i].features[j].properties.visibility
          }
        }
      }
      for (let i = 0; i < FRACTUREAtiv.length; i++) {
        for (let j = 0; j < FRACTUREAtiv[i].features.length; j++) {
          if (titulo === FRACTUREAtiv[i].features[j].properties.ai_object_name) {
            FRACTUREAtiv[i].features[j].properties.visibility = !FRACTUREAtiv[i].features[j].properties.visibility
          }
        }
      }
    } else if (mode === false) {
      for (let i = 0; i < DATAIASELECTEDEnc.length; i++) {
        for (let j = 0; j < DATAIASELECTEDEnc[i].features.length; j++) {
          if (titulo === DATAIASELECTEDEnc[i].features[j].properties.ai_object_name) {
            DATAIASELECTEDEnc[i].features[j].properties.visibility = !DATAIASELECTEDEnc[i].features[j].properties.visibility
          }
        }
      }
      for (let i = 0; i < FRACTUREEnc.length; i++) {
        for (let j = 0; j < FRACTUREEnc[i].features.length; j++) {
          if (titulo === FRACTUREEnc[i].features[j].properties.ai_object_name) {
            FRACTUREEnc[i].features[j].properties.visibility = !FRACTUREEnc[i].features[j].properties.visibility
          }
        }
      }
    } else if (mode === null || mode === undefined) {
      for (let i = 0; i < DATAIASELECTEDNull.length; i++) {
        for (let j = 0; j < DATAIASELECTEDNull[i].features.length; j++) {
          if (titulo === DATAIASELECTEDNull[i].features[j].properties.ai_object_name) {
            DATAIASELECTEDNull[i].features[j].properties.visibility = !DATAIASELECTEDNull[i].features[j].properties.visibility
          }
        }
      }
      for (let i = 0; i < FRACTURENull.length; i++) {
        for (let j = 0; j < FRACTURENull[i].features.length; j++) {
          if (titulo === FRACTURENull[i].features[j].properties.ai_object_name) {
            FRACTURENull[i].features[j].properties.visibility = !FRACTURENull[i].features[j].properties.visibility
          }
        }
      }
    }
    let duplicatedValue = false
    for (let i = 0; i < list.length; i++) {
      if (list[i] === titulo) {
        list.splice(i, 1); duplicatedValue = true
      }
    }
    if (!duplicatedValue) { list.push(titulo) }

    RefreshMap()
  } catch (e) { console.log("desativarlayer", e) }
}

export function toggleLayerDeck(mode) {
  for (let i = 0; i < DATA.length; i++) {
    for (let j = 0; j < DATA[i].features.length; j++) {
      DATA[i].features[j].properties.visibility = mode
    }
  }
  for (let i = 0; i < FRACTUREAtiv.length; i++) {
    for (let j = 0; j < FRACTUREAtiv[i].features.length; j++) {
      FRACTUREAtiv[i].features[j].properties.visibility = mode
    }
  }
  for (let i = 0; i < FRACTUREEnc.length; i++) {
    for (let j = 0; j < FRACTUREEnc[i].features.length; j++) {
      FRACTUREEnc[i].features[j].properties.visibility = mode
    }
  }
  for (let i = 0; i < FRACTURENull.length; i++) {
    for (let j = 0; j < FRACTURENull[i].features.length; j++) {
      FRACTURENull[i].features[j].properties.visibility = mode
    }
  }
  RefreshMap()
}

export function mixDatas(paramFixos, paramDinamicos) {
  let fixos = Object.assign({}, paramFixos);
  for (let i = 0; i < fixos.features.length; i++) {
    const output = Object.assign(Object.assign({}, fixos.features[i].properties), paramDinamicos[i]);
    fixos.features[i].properties = output
  }
  return fixos
}

export function changeMapColors(response) {
  cleanFilterHexagons()
  let workerOrganize = []
  workerOrganize.push(response); workerOrganize.push(fixos)
  workerOrganize.push(getIdentifierId()); workerOrganize.push(getOpacidade())
  workerOrganize.push(getidentifier()); workerOrganize.push(tituloLegenda);
  instanceTime.postMessage(workerOrganize)
  instanceTime.onmessage = (message) => {
    jsonData = message.data[0];
    selectedStreet = message.data[1]
    DATA = message.data[2];
    DATAAUX = message.data[3];
    newMapInfos()
  };
}

let newMapCounter = 0
export function newMapInfos() {
  newMapCounter++
  if (getModeMap() === "rugosidade ia") { if (newMapCounter === 2) { changeMapAux() } }
  else { if (newMapCounter === 1) { changeMapAux() } }
}

async function changeMapAux() {
  const container = document.querySelectorAll(".toggleSessionsSub")
  const conditions = setClicked(container)
  newMapCounter = 0;
  const formated = generateHTML(predefinicoes, IA, jsonData, DATA);
  htmlSidebar = formated[0]
  // htmlSidebarCadastro = formated[1]
  // htmlSidebarAnalises = formated[2]
  // htmlFilterSidebar = formated[1]
  StartSidebar()
  changeMonitoramentoHeight()
  setTimeout(() => {
    enableAllModes("Monitoramento")
    enableAllModes("Inventário")
    enableAllModes("Análise")
    setTimeout(() => {
      disableAllModes("Monitoramento")
      disableAllModes("Inventário")
      disableAllModes("Análise")
      setTimeout(() => {
        for (let i = 0; i < container.length; i++) {
          for (let j = 0; j < conditions.length; j++) {
            if (conditions[j] === container[i].querySelectorAll(".gridSessionsEnable")[0].textContent) {
              container[i].querySelectorAll(".gridSessionsEnable")[0].parentNode.getElementsByTagName("input")[0].click()
            } else {
              if (container[i].querySelectorAll(".gridSessionsEnable")[0].parentNode.getElementsByTagName("input")[0].checked === true) {
                container[i].querySelectorAll(".gridSessionsEnable")[0].parentNode.getElementsByTagName("input")[0].click()
              }
            }
          }
        }
        updateChartHeight()
        Loading("fechar");
        RefreshMap()
      }, 1100);
    }, 1100);
  }, 100);
}

export function changeMonitoramentoHeight() {
  const element = document.querySelectorAll(".gridSessionsEnable")[0].parentNode.parentNode
  element.style.paddingBottom = "40px"
}

let counterMain = 0
function startApplication() {
  counterMain++;
  if (getModeMap() === "rugosidade ia") {
    if (counterMain === 4) { main() }
  } else if (getModeMap() === "ia") {
    if (counterMain === 2) { main() }
  } else if (getModeMap() === "rugosidade") {
    if (counterMain === 3) { main() }
  }
}

let counterIA = 0
async function prepareIA() {
  counterIA++;
  if (counterIA === 2) { IA = await prepareDataIA(IA, IASeparator); startApplication() }
}

let dadosfato, dadosia, apiimagens;
export function getDadosfato() {
  return dadosfato
}
export function getApiimagens() {
  return apiimagens
}
export function getList() {
  let ids = []

  for (let i = 0; i < IA.features.length; i++) {
    for (let j = 0; j < list.length; j++) {
      if (IA.features[i].properties.ai_object_name !== undefined) {
        if (list[j] === IA.features[i].properties.ai_object_name) {
          const splitted = list[j].split(" - ")
          if (splitted[1] === "Ativo") {
            ids[j] = IA.features[i].properties.ai_object_id + " - true"
          } else if (splitted[1] === "Encerrado") {
            ids[j] = IA.features[i].properties.ai_object_id + " - false"
          } else {
            ids[j] = IA.features[i].properties.ai_object_id + " - null"
          }
        }
      }
    }
  }
  return ids
}

async function doRequests() {
  try {
    RefreshMap()
    startSplashScreen()
    let listaFato, trechos, classesia, listaIa;
    await axios.get(APIcidade)
      .then((response) => {
        predefinicoes = response.data;
        console.log(predefinicoes);
        apiimagens = predefinicoes.apiimagens;
        listaIa = predefinicoes.apilistaia;
        listaFato = predefinicoes.apilistafato;
        trechos = predefinicoes.apitrechos;
        dadosfato = predefinicoes.apidadosfato;
        dadosia = predefinicoes.apidadosia;
        classesia = predefinicoes.apiclassesia;

        axios.get(response.data.extensaoruas)
          .then((response) => {
            extensaoruas = response.data;
          })
          .catch(error => {
            console.error("Error fetching extensaoruas:", error);
          });

        let coordinate = response.data.coordenadas;
        setModeMap(response.data.modos);
        setCoordinates(coordinate[0], coordinate[1], coordinate[2]);
        setidentifier("nome" + predefinicoes.filtrosregionais[0]);
        setModo(predefinicoes.filtrosregionais[0]);

        axios.get(response.data.apifiltrosregionais)
          .then((response) => {
            setList(response.data);
            startApplication();
          })
      })
      .catch(error => {
        location.reload()
        console.error("Error fetching APIcidade:", error);
      });

    if (predefinicoes.analisefiltros) {
      // for (let i = 0; i < predefinicoes.analisefiltros.length; i++) {
      //   await axios.get(predefinicoes.apianalisefiltros[i]).then((response) => {
      //     for (let j = 0; j < response.data.features.length; j++) {
      //       response.data.features[j].properties.analise = true
      //     }
      //     apianalisefiltros.push(response.data)
      //   })
      // }
    }
    let listaFATO, listaIA
    if (listaFato) {
      listaFATO = await getRequest(listaFato)
    }
    if (listaIa) {
      listaIA = await getRequest(listaIa)
    }
    if (getModeMap() === "rugosidade ia" || getModeMap() === "rugosidade") {
      if (listaIA) {
        const month = findLastEqualElement(listaIA, listaFATO)
        axios.get(dadosfato + month).then((response) => { dinamicos = response.data; startApplication() })
      } else {
        let listaCounter = listaFATO.length - 1
        axios.get(dadosfato + listaFATO[listaCounter]).then((response) => { dinamicos = response.data; startApplication() })
      }
      axios.get(trechos).then((response) => { fixos = response.data; startApplication() })
    }
    if (getModeMap() === "rugosidade ia" || getModeMap() === "ia") {
      if (listaFATO) {
        const month = findLastEqualElement(listaIA, listaFATO)
        axios.get(dadosia + month).then((response) => { IA = response.data; prepareIA() })
      } else {
        let listaCounter = listaIA.length - 1
        axios.get(dadosia + listaIA[listaCounter]).then((response) => { IA = response.data; prepareIA() })
      }
      axios.get(classesia).then((response) => { IASeparator = response.data; prepareIA() })
    }
  } catch (error) {
    console.log(error)
  }
}
doRequests()

export function findLastEqualElement(arr1, arr2) {
  let primaryArray = arr1.length < arr2.length ? arr1 : arr2;
  let secondaryArray = primaryArray === arr1 ? arr2 : arr1;

  for (let i = primaryArray.length - 1; i >= 0; i--) {
    if (secondaryArray.includes(primaryArray[i])) {
      return primaryArray[i];
    }
  }
  return null;
}

export function filterBySize() {
  const deButton = document.getElementById("deButton")
  const ateButton = document.getElementById("ateButton")
  if (ateButton.value !== "" && deButton.value !== "") {
    if (parseInt(ateButton.value) > parseInt(deButton.value)) {
      Loading("iniciar")
      setTimeout(() => { changeExtension(parseInt(deButton.value), parseInt(ateButton.value)) }, 10);
    }
  } else if (ateButton.value === "" && deButton.value !== "") {
    Loading("iniciar")
    setTimeout(() => { changeExtension(parseInt(deButton.value), undefined) }, 10);
  } else if (ateButton.value !== "" && deButton.value === "") {
    Loading("iniciar")
    setTimeout(() => { changeExtension(undefined, parseInt(ateButton.value)) }, 10);
  } else {
    Loading("iniciar")
    setTimeout(() => { changeExtension(undefined, undefined) }, 10);
  }
}

function changeExtension(initial, final) {
  let auxJsonData = {
    "name": "layer",
    "type": "FeatureCollection",
    "features": []
  }

  let auxIA = {
    "name": "layer",
    "type": "FeatureCollection",
    "features": []
  }

  let selectedList = extensaoruas;

  if (initial !== undefined) {
    selectedList = selectedList.filter((item) => item.extensao_via >= initial);
  }

  if (final !== undefined) {
    selectedList = selectedList.filter((item) => item.extensao_via <= final);
  }

  if (getModeMap() === 'rugosidade ia' || getModeMap() === 'rugosidade') {
    for (const feature of jsonData.features) {
      if (selectedList.some((item) => item.nomeLogradouro === feature.properties.nomeLogradouro)) {
        auxJsonData.features.push(feature);
      }
    }
  } else if (getModeMap() === 'rugosidade ia' || getModeMap() === 'ia') {
    for (const feature of IA.features) {
      if (selectedList.some((item) => item.nomeLogradouro === feature.properties.nomeLogradouro)) {
        auxIA.features.push(feature);
      }
    }
  }

  if (initial === undefined && final === undefined) {
    auxJsonData = jsonData
    auxIA = IA
  }

  if (getModeMap() === "rugosidade ia" || getModeMap() === "rugosidade") {
    DATA = distributeAlgorithms(auxJsonData, tituloLegenda)
  }
  if (getModeMap() === "rugosidade ia" || getModeMap() === "ia") {
    DATAIASELECTED = distributeAlgorithmsIA(auxIA, "deteccao_ativa")
    for (let i = 0; i < DATAIASELECTED.length; i++) {
      const aux = distributeAlgorithmsIA(DATAIASELECTED[i], "ai_object_name")
      if (DATAIASELECTED[i].name === true) { DATAIASELECTEDAtiv = aux }
      else if (DATAIASELECTED[i].name === false) { DATAIASELECTEDEnc = aux }
      else if (DATAIASELECTED[i].name === null) { DATAIASELECTEDNull = aux }
    }
  }
  setTimeout(() => { Loading("fechar"); RefreshMap() }, 500);
}

export async function main() {
  let workerOrganize = []
  workerOrganize.push(dinamicos); workerOrganize.push(fixos)
  workerOrganize.push(tituloLegenda);
  document.body.style.cursor = "wait"
  async function loop() {
    if (getModeMap() === "rugosidade ia" || getModeMap() === "rugosidade") {
      instanceMain.postMessage(workerOrganize)
      instanceMain.onmessage = (message) => {
        setLat(predefinicoes.coordenadas[0])
        setLng(predefinicoes.coordenadas[1])
        setZoomMap(predefinicoes.coordenadas[2])
        jsonData = message.data[0];
        console.log("jsonData", jsonData)
        DATA = message.data[1];
        const formated = generateHTML(predefinicoes, IA, jsonData, DATA);
        htmlSidebar = formated[0]
        // htmlSidebarCadastro = formated[1]
        // htmlSidebarAnalises = formated[2]
        // htmlFilterSidebar = formated[1]
        // for (let i = 0; i < getMapFilters().features.length; i++) {
        //   getMapFilters().features[i].properties.visibility = true
        //   getMapFilters().features[i].properties.distritos = true
        // }
        if (getModeMap() === "rugosidade ia" || getModeMap() === "ia") {
          startTimeControl()
        } else {
          newMapInfos()
          // document.getElementById("noFilter").click()
        }
        finishAnimation()
      };
    } else {
      const formated = generateHTML(predefinicoes, IA, jsonData, DATA);
      htmlSidebar = formated[0]
      // htmlSidebarCadastro = formated[1]
      // htmlSidebarAnalises = formated[2]
      // htmlFilterSidebar = formated[1]
      // for (let i = 0; i < getMapFilters().features.length; i++) {
      //   getMapFilters().features[i].properties.visibility = true
      //   getMapFilters().features[i].properties.distritos = true
      // }
      startTimeControl()
      finishAnimation()
    }
  }
  loop()
}

export async function addSelectedGeometry(geometry) {
  let duplicatedValue = false
  for (let k = 0; k < selectedGeometry.features.length; k++) {
    if (selectedGeometry.features[k] === geometry) { duplicatedValue = true }
  }
  if (!duplicatedValue) {
    selectedGeometry.features.push(geometry)
    DATASELECTED = distributeAlgorithms(selectedGeometry, tituloLegenda)
    RefreshMap()
  }
}

export function cleanSelectedGeometry() {
  selectedGeometry = {
    "name": "layer",
    "type": "FeatureCollection",
    "features": []
  }
  DATASELECTED = []
}

export function cleanFilterHexagons() {
  selectedStreet = {
    "name": "layer",
    "type": "FeatureCollection",
    "features": []
  }
}

export function cleanFundo() {
  FUNDO = []
}

export function zoomOut() {
  setZoomMap(getZoomMap() - 0.7)
  changeViewState(getLng() + 0.00000000001, getLat(), 100, getZoomMap())
  RefreshMap()
}

export function zoomIn() {
  setZoomMap(getZoomMap() + 0.7)
  changeViewState(getLng() + 0.00000000001, getLat(), 100, getZoomMap())
  RefreshMap()
}

export function fixOrientation2d() {
  changeViewState(getLng() + 0.00000000001, getLat(), 100, getZoomMap())
  RefreshMap()
}

export function fixOrientation3d() {
  changeViewState(getLng() + 0.00000000001, getLat(), 100, getZoomMap(), 90, 350)
  RefreshMap()
}

export function changeMapMode(condition) {
  ResetJsonData()
  cleanFilterHexagons()
  setOpacidade(condition)
  Distrito(!condition)
  RefreshMap()
}

export function SidebarFilterFunction(info) {
  let auxJsonData = {
    "name": "layer",
    "type": "FeatureCollection",
    "features": []
  }

  let auxJsonDataIA = {
    "name": "layer",
    "type": "FeatureCollection",
    "features": []
  }

  document.getElementById("leg").classList.add("active")
  const conditions = ResetJsonData()
  cleanFilterHexagons()
  setOpacidade(true)
  setTypeFilter(info)
  document.getElementById("typeFilter").value = info

  function selectMap(json) {
    for (let i = 0; i < json.features.length; i++) {
      if (json.features[i].properties[getidentifier()] !== undefined && json.features[i].properties[getidentifier()] !== null) {
        // console.log(json.features[i].properties)
        if (json.features[i].properties[getidentifier()].toUpperCase() === info) {
          setIdentifierId(json.features[i].properties[getidentifier()])
          selectedStreet.features.push(json.features[i])
          auxJsonData.features.push(json.features[i])
        } else {
          auxJsonData.features.push(json.features[i])
        }
      }
    }
  }

  function selectMapIA() {
    for (let i = 0; i < IA.features.length; i++) {
      if (IA.features[i].properties[getidentifier()] !== undefined && IA.features[i].properties[getidentifier()] !== null) {
        if (IA.features[i].properties[getidentifier()].toUpperCase() === info) {
          auxJsonDataIA.features.push(IA.features[i])
        }
      }
    }
  }

  // let fundo;
  // for (let i = 0; i < getGlobalFilters()[getSeparador()].features.length; i++) {
  //   if (getGlobalFilters()[getSeparador()].features[i].properties.nome.toUpperCase() === info) {
  //     fundo = JSON.parse(JSON.stringify(getGlobalFilters()[getSeparador()].features[i]));
  //     fundo.properties.noPopup = true
  //   }
  // }
  // FUNDO = fundo
  // 
  let centroid;
  if (getModeMap() === "rugosidade ia" || getModeMap() === "rugosidade") {
    selectMap(jsonData)
    DATAAUX = distributeAlgorithms(selectedStreet, tituloLegenda)
    DATA = distributeAlgorithms(auxJsonData, tituloLegenda)
    centroid = turf.center(selectedStreet);
  }
  if (getModeMap() === "rugosidade ia" || getModeMap() === "ia") {
    selectMapIA()
    DATAIASELECTED = distributeAlgorithmsIA(auxJsonDataIA, "deteccao_ativa")
    if (auxJsonDataIA.features.length !== 0) {
      centroid = turf.center(auxJsonDataIA);
    }
    for (let i = 0; i < DATAIASELECTED.length; i++) {
      const aux = distributeAlgorithmsIA(DATAIASELECTED[i], "ai_object_name")
      if (DATAIASELECTED[i].name === true) { DATAIASELECTEDAtiv = aux }
      else if (DATAIASELECTED[i].name === false) { DATAIASELECTEDEnc = aux }
      else if (DATAIASELECTED[i].name === null) { DATAIASELECTEDNull = aux }
    }
  }

  if (getZoomMap() < 10) {
    if (getOrientation() === false) {
      changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, 12)
    } else if (getOrientation() === true) {
      changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, 12)
    } else {
      firstFilter()
      changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, 12)
    }
  } else {
    if (getOrientation() === false) {
      changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, getZoomMap())
    } else if (getOrientation() === true) {
      changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, getZoomMap())
    } else {
      firstFilter()
      changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, getZoomMap())
    }
  }

  RefreshMap();
  const formated = generateHTML(predefinicoes, auxJsonDataIA, selectedStreet, DATA);
  htmlSidebar = formated[0]
  // htmlSidebarCadastro = formated[1]
  // htmlSidebarAnalises = formated[2]
  // htmlFilterSidebar = formated[1]
  StartSidebar()
  disableAllModes("Monitoramento")
  disableAllModes("Inventário")
  disableAllModes("Análise")
  const container = document.querySelectorAll(".toggleSessionsSub")
  setTimeout(() => {
    for (let i = 0; i < container.length; i++) {
      for (let j = 0; j < conditions.length; j++) {
        if (conditions[j] === container[i].querySelectorAll(".gridSessionsEnable")[0].textContent) {
          container[i].querySelectorAll(".gridSessionsEnable")[0].parentNode.getElementsByTagName("input")[0].click()
        }
      }
    }
    changeMonitoramentoHeight()
    updateChartHeight();
    setTimeout(() => { Loading("fechar") }, 200);
  }, 1000);

}

export function updateChartHeight() {
  document.querySelectorAll(".chartSpacing").forEach(element => {
    const height = element.offsetHeight
    element.querySelectorAll("div canvas").forEach(canvas => {
      if (height > 80) { canvas.style.height = height - 10 + "px" }
      else { canvas.style.height = height + "px" }
    });
  });
}

// function FilterByJson(info) {
//   Loading("iniciar")
//   cleanFilterHexagons()
//   setOpacidade(true)
//   DATAAUX = []

//   let centroid = turf.centroid(info.object);
//   if (info.viewport.zoom < 10) {
//     if (getOrientation() === false) {
//       changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, 12)
//     } else if (getOrientation() === true) {
//       changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, 12)
//     } else {
//       firstFilter()
//       changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, 12)
//     }
//   } else {
//     if (getOrientation() === false) {
//       changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, info.viewport.zoom)
//     } else if (getOrientation() === true) {
//       changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, info.viewport.zoom)
//     } else {
//       firstFilter()
//       changeViewState(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1], 1000, info.viewport.zoom)
//     }
//   }

//   let auxJsonData = {
//     "name": "layer",
//     "type": "FeatureCollection",
//     "features": []
//   }

//   let auxJsonDataIA = {
//     "name": "layer",
//     "type": "FeatureCollection",
//     "features": []
//   }

//   const id = info.object.properties.nome
//   function selectMap(json) {
//     for (let i = 0; i < json.features.length; i++) {
//       setIdentifierId(id)
//       if (json.features[i].properties[getidentifier()] === id) {
//         selectedStreet.features.push(json.features[i])
//         auxJsonData.features.push(json.features[i])
//       } else {
//         auxJsonData.features.push(json.features[i])
//       }
//     }
//   }

//   function selectMapIA() {
//     for (let i = 0; i < IA.features.length; i++) {
//       if (IA.features[i].properties[getidentifier()] === id) {
//         auxJsonDataIA.features.push(IA.features[i])
//       }
//     }
//   }

//   if (getModeMap() === "rugosidade ia" || getModeMap() === "rugosidade") {
//     selectMap(jsonData)
//   }

//   if (getModeMap() === "rugosidade ia" || getModeMap() === "ia") {
//     selectMapIA()
//   }

//   if (getModeMap() === "rugosidade ia" || getModeMap() === "rugosidade") {
//     DATAAUX = distributeAlgorithms(selectedStreet, tituloLegenda)
//     DATA = distributeAlgorithms(auxJsonData, tituloLegenda)
//   }
//   if (getModeMap() === "rugosidade ia" || getModeMap() === "ia") {
//     selectMapIA()
//     DATAIASELECTED = distributeAlgorithmsIA(auxJsonDataIA, "deteccao_ativa")
//     for (let i = 0; i < DATAIASELECTED.length; i++) {
//       const aux = distributeAlgorithmsIA(DATAIASELECTED[i], "ai_object_name")
//       if (DATAIASELECTED[i].name === true) { DATAIASELECTEDAtiv = aux }
//       else if (DATAIASELECTED[i].name === false) { DATAIASELECTEDEnc = aux }
//       else if (DATAIASELECTED[i].name === null) { DATAIASELECTEDNull = aux }
//     }
//   }
//   let fundo = JSON.parse(JSON.stringify(info.object));
//   fundo.properties.noPopup = true
//   FUNDO = fundo
//   RefreshMap()
//   Loading("fechar")
// }

function setClicked(container) {
  const conditions = []
  if (predefinicoes.analisefiltros) {
    for (let i = 0; i < container.length - predefinicoes.analisefiltros.length; i++) {
      const inputs = container[i].getElementsByTagName("input")[0];
      if (inputs.checked === true) {
        conditions.push(inputs.parentNode.querySelectorAll(".gridSessionsEnable")[0].textContent)
      }
    }
  } else {
    for (let i = 0; i < container.length.length; i++) {
      const inputs = container[i].getElementsByTagName("input")[0];
      if (inputs.checked === true) {
        conditions.push(inputs.parentNode.querySelectorAll(".gridSessionsEnable")[0].textContent)
      }
    }
  }
  return conditions
}

export function ResetJsonData(map) {
  document.getElementById("typeFilter").value = ""
  setTypeFilter("")
  const container = document.querySelectorAll(".toggleSessionsSub")
  const conditions = setClicked(container)
  setOpacidade(false)
  if (getModeMap() === "rugosidade ia" || getModeMap() === "rugosidade") {
    DATA = distributeAlgorithms(jsonData, tituloLegenda)
    DATAAUX = []
  }
  if (getModeMap() === "rugosidade ia" || getModeMap() === "ia") {
    DATAIASELECTED = []
  }
  FUNDO = []
  const formated = generateHTML(predefinicoes, IA, jsonData, DATA);
  htmlSidebar = formated[0]
  // htmlSidebarCadastro = formated[1]
  // htmlSidebarAnalises = formated[2]
  // htmlFilterSidebar = formated[1]
  StartSidebar()
  if (map) {
    setTimeout(() => {
      disableAllModes("Monitoramento")
      disableAllModes("Inventário")
      disableAllModes("Análise")
      setTimeout(() => {
        for (let i = 0; i < container.length; i++) {
          for (let j = 0; j < conditions.length; j++) {
            if (conditions[j] === container[i].querySelectorAll(".gridSessionsEnable")[0].textContent) {
              container[i].querySelectorAll(".gridSessionsEnable")[0].parentNode.getElementsByTagName("input")[0].click()
            } else {
              if (container[i].querySelectorAll(".gridSessionsEnable")[0].parentNode.getElementsByTagName("input")[0].checked === true) {
                container[i].querySelectorAll(".gridSessionsEnable")[0].parentNode.getElementsByTagName("input")[0].click()
              }
            }
          }
        }
        setTimeout(() => {
          updateChartHeight()
          changeMonitoramentoHeight()
        }, 2000);
      }, 1000);
    }, 100);
  }
  Loading("fechar")
  return conditions
}

export async function changeDateMap(date) {
  let auxJsonDataIA = {
    "name": "layer",
    "type": "FeatureCollection",
    "features": []
  }

  if (openPage !== undefined) {
    IA = await getRequest(`${dadosia}${date}.json`)
  }
  function selectMapIA() {
    for (let i = 0; i < IA.features.length; i++) {
      if (IA.features[i].properties[getidentifier()] === getIdentifierId()) {
        auxJsonDataIA.features.push(IA.features[i])
      }
    }
  }
  async function loopChangeDate() {
    if (getModeMap() === "rugosidade ia" || getModeMap() === "ia") {
      if (IA !== undefined) {
        console.log("IA", IA)
        if (openPage !== undefined) { IA = await prepareDataIA(IA, IASeparator) }
        DATAIA = distributeAlgorithmsIA(IA, "classeprincipal")
        FRACTURE = distributeAlgorithmsDistributed(DATAIA, "deteccao_ativa")
        for (let i = 0; i < FRACTURE.length; i++) {
          const aux = distributeAlgorithmsIA(FRACTURE[i], "ai_object_name")
          if (FRACTURE[i].name === true) { FRACTUREAtiv = aux }
          else if (FRACTURE[i].name === false) { FRACTUREEnc = aux }
          else if (FRACTURE[i].name === null || FRACTURE[i].name === undefined) { FRACTURENull = aux }
        }
        if (getOpacidade() === true) {
          selectMapIA(IA)
          DATAIASELECTED = distributeAlgorithmsIA(auxJsonDataIA, "deteccao_ativa")
          for (let i = 0; i < DATAIASELECTED.length; i++) {
            const aux = distributeAlgorithmsIA(DATAIASELECTED[i], "ai_object_name")
            if (DATAIASELECTED[i].name === true) { DATAIASELECTEDAtiv = aux }
            else if (DATAIASELECTED[i].name === false) { DATAIASELECTEDEnc = aux }
            else if (DATAIASELECTED[i].name === null) { DATAIASELECTEDNull = aux }
          }
        }
        for (let i = 0; i < IA.features.length; i++) {
          let duplicatedValue = false
          for (let k = 0; k < list.length; k++) {
            if (list[k] === IA.features[i].properties.ai_object_name) { duplicatedValue = true }
          }
          if (!duplicatedValue) { list.push(IA.features[i].properties.ai_object_name) }
        }

        if (openPage === undefined) {
          RefreshMap(); Loading("fechar");
          // document.getElementById("noFilter").click()
        }
        else { newMapInfos() }
        openPage = false
      } else { loopChangeDate() }
    } else {
      openPage = false
      RefreshMap()
      // iniciarLegenda(DATA, DATAIA)
      Loading("fechar")
    }
  }
  loopChangeDate()
}

export function RefreshMap() {
  if (predefinicoes !== undefined) {
    polygon = [];
    polygonIAAtiv = [];
    polygonIAEnc = [];
    polygonIANull = [];
    polygonIASELECTEDAtiv = [];
    polygonIASELECTEDEnc = [];
    polygonIASELECTEDNull = [];
    selectedStreetArray = [];
    selectedGeometryArray = [];
    analisesArray = [];
    RelatorioPolylineDeck = [];
    let rugIaMode;

    const inputs = document.querySelectorAll(".checkboxSidebar");
    let rugosidade = false;
    let ia = false;

    inputs.forEach((input, i) => {
      if (getModeMap() === "rugosidade ia" || getModeMap() === "rugosidade") {
        if (i === 0 && input.checked) {
          rugosidade = true;
        } else if (input.checked) {
          ia = true;
        }
      } else {
        rugosidade = true;
        ia = true;
      }
    });

    setTilesMapStyle(rugosidade && ia);
    // const widthMode = rugosidade && ia ? "lineWidthMinPixels" : "getLineWidth";
    // eslint-disable-next-line no-inner-declarations
    function createLayer(id, data, visibility) {
      if (visibility) {
        rugIaMode = visibility
      }
      return new GeoJsonLayer({
        id,
        data,
        stroked: true,
        filled: true,
        visible: visibility,
        lineWidthMinPixels: 3,
        opacity: (getOpacidade() === true) ? 0 : 0.9,
        getLineColor: f => f.properties.ai_object_color_rgb,
        getFillColor: f => f.properties.ai_object_color_rgb,
        pickable: true,
        autoHighlight: true,
        highlightColor: f => f.object.properties.ai_object_color_rgb,
      });
    }

    // eslint-disable-next-line no-inner-declarations
    function createSelectedLayer(id, data, visibility) {
      if (visibility) {
        rugIaMode = visibility
      }
      return new GeoJsonLayer({
        id,
        data,
        stroked: true,
        filled: true,
        visible: visibility,
        lineWidthMinPixels: 3,
        opacity: 0.9,
        getLineColor: f => f.properties.ai_object_color_rgb,
        getFillColor: f => f.properties.ai_object_color_rgb,
        pickable: true,
        autoHighlight: true,
        highlightColor: f => f.object.properties.ai_object_color_rgb,
      });
    }

    for (let i = 0; i < FRACTUREAtiv.length; i++) {
      const layerIA = createLayer("IAATiv" + i, FRACTUREAtiv[i], FRACTUREAtiv[i].features[0].properties.visibility);
      polygonIAAtiv.push(layerIA);
    }

    for (let i = 0; i < FRACTUREEnc.length; i++) {
      const layerIA = createLayer("IAEnc" + i, FRACTUREEnc[i], FRACTUREEnc[i].features[0].properties.visibility);
      polygonIAEnc.push(layerIA);
    }

    for (let i = 0; i < FRACTURENull.length; i++) {
      const layerIA = createLayer("IANull" + i, FRACTURENull[i], FRACTURENull[i].features[0].properties.visibility);
      polygonIANull.push(layerIA);
    }

    for (let i = 0; i < DATAIASELECTEDAtiv.length; i++) {
      const layerIA = createSelectedLayer("IASELECTEDAtiv" + i, DATAIASELECTEDAtiv[i], DATAIASELECTEDAtiv[i].features[0].properties.visibility);
      polygonIASELECTEDAtiv.push(layerIA);
    }

    for (let i = 0; i < DATAIASELECTEDEnc.length; i++) {
      const layerIA = createSelectedLayer("IASELECTEDEnc" + i, DATAIASELECTEDEnc[i], DATAIASELECTEDEnc[i].features[0].properties.visibility);
      polygonIASELECTEDEnc.push(layerIA);
    }

    for (let i = 0; i < DATAIASELECTEDNull.length; i++) {
      const layerIA = createSelectedLayer("IASELECTEDNull" + i, DATAIASELECTEDNull[i], DATAIASELECTEDNull[i].features[0].properties.visibility);
      polygonIASELECTEDNull.push(layerIA);
    }

    for (let j = 0; j < DATA.length; j++) {
      if (DATA[j].features[0].properties.visibility) {
        rugIaMode = DATA[j].features[0].properties.visibility
      }
      const layerDeck = new GeoJsonLayer({
        id: j,
        data: DATA[j],
        stroked: true,
        filled: true,
        visible: DATA[j].features[0].properties.visibility,
        lineWidthMinPixels: 2,
        // getLineWidth: 10,
        opacity: (rugosidade && ia) ? 0.4 : 1,
        getLineColor: f => prepareColors(f.properties[hexColorJson]),
        getFillColor: f => prepareColors(f.properties[hexColorJson]),
        pickable: true,
        autoHighlight: true,
        highlightColor: f => prepareColors(f.object.properties[hexColorJson]),
      });
      polygon.push(layerDeck);
    }

    for (let j = 0; j < DATAAUX.length; j++) {
      if (DATAAUX[j].features[0].properties.visibility) {
        rugIaMode = DATAAUX[j].features[0].properties.visibility
      }
      const selectedStreetDeck = new GeoJsonLayer({
        id: "regiaoSelecionada" + j,
        data: DATAAUX[j],
        stroked: true,
        filled: true,
        visible: DATAAUX[j].features[0].properties.visibility,
        lineWidthMinPixels: 2,
        // getLineWidth: 10,
        opacity: (rugosidade && ia) ? 0.4 : 1,
        getLineColor: f => prepareColors(f.properties[hexColorJson], "opacidade"),
        getFillColor: f => prepareColors(f.properties[hexColorJson], "opacidade"),
        pickable: true,
        autoHighlight: true,
        highlightColor: f => prepareColors(f.object.properties[hexColorJson], "opacidade"),
      });
      selectedStreetArray.push(selectedStreetDeck);
    }

    for (let j = 0; j < DATASELECTED.length; j++) {
      const selectedGeometryDeck = new GeoJsonLayer({
        id: "selectedGeometry" + j,
        data: DATASELECTED[j],
        stroked: true,
        filled: true,
        opacity: 1,
        lineWidthMinPixels: 2,
        // getLineWidth: 10,
        getLineColor: [47, 120, 228],
        getFillColor: [47, 120, 228],
        pickable: true,
        autoHighlight: true,
        highlightColor: [47, 120, 228],
      });
      selectedGeometryArray.push(selectedGeometryDeck);
    }

    if (apianalisefiltros && apianalisefiltros.features.length !== 0 && !rugIaMode) {
      const analises = new GeoJsonLayer({
        id: "apianalisefiltros",
        data: apianalisefiltros,
        stroked: true,
        filled: true,
        visible: apianalisefiltros.features[0].properties.visibility,
        opacity: 1,
        lineWidthMinPixels: 2,
        // getLineWidth: 5,
        getLineColor: f => f.properties.cor_rgb,
        getFillColor: f => f.properties.cor_rgb,
        pickable: true,
        autoHighlight: true,
        highlightColor: f => f.object.properties.cor_rgb,
      });
      analisesArray.push(analises);
    }

    fundoDeck = new GeoJsonLayer({
      id: "fundo",
      data: FUNDO,
      stroked: true,
      filled: true,
      opacity: 1,
      getLineColor: [0, 0, 0, 0],
      getFillColor: [0, 0, 0, 0],
      pickable: true,
      autoHighlight: true,
      highlightColor: [0, 0, 0, 0],
    });
  }

  ReactDOM.render(<Rugosidade />, document.getElementById("app"));
}

function Rugosidade() {
  return (
    <>
      {predefinicoes !== undefined ?
        <>
          <SidebarGeovista
            logoURL={predefinicoes.logourl}
            titlesBackground="#fff"
            titlesColor="#000"
            titlesSize="15px"
            borderRadius="0px"
            borderRadiusSub="0px"
            titlesBorderColor="#d6d6d6"
            modelHeight="30px"
            zIndex={1000}
            classesResponsiveResize={['SidebarContentModel']}
            // NavigationMenuIcon={["fas fa-user", "fas fa-search", "fas fa-marker", "fas fa-map"]}
            buttons={{
              'icons': ['fas fa-exchange', 'fas fa-print', 'fas fa-table'],
              'ids': ['toggle', 'print', 'csvid'],
              'titles': ['toggle', 'print', 'Baixar Planilha'],
              'functions': ['toggleSidebar', 'printScreen', () => downloadCsv(jsonData, IA)]
            }}
          >
            <div>
              {/* <div model="false">
                <div id="fixedFilter">
                  <div id="sessionPanel1" className="barraFilter">
                    <TypeFilter />
                  </div>
                  <div id="FilterCentral" className="FilterCentral">
                    <div className="controlFilter">
                      <ul>
                        {htmlFilterSidebar}
                      </ul>
                    </div>
                  </div>
                  <div className="timeControlCenter">
                    <PrepareTimeControl DATA={DATA}></PrepareTimeControl>
                  </div>
                </div>
                <div className="toggleMonitoramento">
                  <div className="toggleLegend">
                    <label className="switch">
                      <input id="toggleInput" type="checkbox" onClick={(e) => toggleMonitoramento(e, "Monitoramento")} defaultChecked></input>
                      <span className="sliderButton round"></span>
                    </label>
                  </div>
                </div>
              </div> */}
              {htmlSidebar}
            </div>
          </SidebarGeovista>
          <LoadingScreen />
          <RightButton />
          <MapControl />
          <TitleInfo />
          <DeckComponent
            states={[
              TILES, polygon, polygonIAAtiv, polygonIAEnc,
              polygonIANull, RelatorioPolylineDeck,
              distritosDeck, fundoDeck, selectedStreetArray,
              polygonIASELECTEDAtiv, polygonIASELECTEDEnc,
              polygonIASELECTEDNull, analisesArray, selectedGeometryArray
            ]}
          >
          </DeckComponent>
        </>
        : null}
      <SplashScreen />
    </>
  );
}

export function toggleMonitoramento(event, mode) {
  document.querySelectorAll(".gridSessionsEnable").forEach(element => {
    if (element.textContent === mode) {
      const container = element.parentNode.parentNode.parentNode.querySelectorAll(".sessionBody")[0].querySelectorAll("div .toggleSessionsSub")
      for (let i = 0; i < container.length; i++) {
        const inputs = container[i].getElementsByTagName("input")[0];
        if (inputs !== undefined && inputs.checked !== event.target.checked) { inputs.click() }
      }
    }
  });
}

export function disableAllModes(mode) {
  document.querySelectorAll(".gridSessionsEnable").forEach(element => {
    if (element.textContent === mode) {
      const container = element.parentNode.parentNode.parentNode.querySelectorAll(".sessionBody")[0].querySelectorAll("div .toggleSessionsSub")
      for (let i = 0; i < container.length; i++) {
        const inputs = container[i].getElementsByTagName("input")[0];
        if (inputs !== undefined && inputs.checked !== false) { inputs.click() }
      }
    }
  });
}

export function enableAllModes(mode) {
  document.querySelectorAll(".gridSessionsEnable").forEach(element => {
    if (element.textContent === mode) {
      const container = element.parentNode.parentNode.parentNode.querySelectorAll(".sessionBody")[0].querySelectorAll("div .toggleSessionsSub")
      for (let i = 0; i < container.length; i++) {
        const inputs = container[i].getElementsByTagName("input")[0];
        if (inputs !== undefined && inputs.checked !== true) { inputs.click() }
      }
    }
  });
}

export function Distrito(
  // paramCond
) {
  // getMapFilters().features[0].properties.visibility = paramCond
  RefreshMap()
}

export function rugosidadeControl(visibility, id) {
  disableAllModes("Análise")
  if (apianalisefiltros) {
    for (let j = 0; j < apianalisefiltros.features.length; j++) {
      apianalisefiltros.features[j].properties.visibility = false
    }
  }
  const content = document.getElementById(id)
  const legendFilter = content.querySelectorAll('.legendFilter')[0]
  const hub = content.querySelectorAll('.legendRight')[0]
  const legenda = hub.querySelectorAll('.legendaChart')[0]
  const chart = hub.querySelectorAll('.chartSpacing')[0]

  if (visibility === false) {
    hub.style.cursor = "not-allowed"
    chart.style.cursor = "initial"
    legenda.style.pointerEvents = "none"
    legendFilter.style.pointerEvents = "none"
    legenda.querySelectorAll(".changeTextColor").forEach(element => {
      const trocarCor = element.querySelectorAll(".trocarCor")[0]
      const tampa = element.querySelectorAll(".tampa")[0]
      element.style.color = "#929292";
      trocarCor.style.opacity = "1"
      tampa.style.opacity = "0";
    });
  } else {
    hub.style.cursor = "initial"
    legenda.style.pointerEvents = "all"
    legendFilter.style.pointerEvents = "all"
    legenda.querySelectorAll(".changeTextColor").forEach(element => {
      const trocarCor = element.querySelectorAll(".trocarCor")[0]
      const tampa = element.querySelectorAll(".tampa")[0]
      element.style.color = "rgb(90, 90, 90)"
      trocarCor.style.opacity = "0"
      tampa.style.opacity = "1";
    });
  }

  for (let i = 0; i < DATA.length; i++) {
    for (let j = 0; j < DATA[i].features.length; j++) {
      DATA[i].features[j].properties.visibility = visibility
      DATA[i].features[j].properties.legend = visibility
    }
  }

  const target = event.target
  target.disabled = true
  setTimeout(() => {
    target.disabled = false
  }, 1000);
  RefreshMap()
}

export function IAControl(visibility, classeprincipal, comp) {
  disableAllModes("Análise")
  if (apianalisefiltros) {
    for (let j = 0; j < apianalisefiltros.features.length; j++) {
      apianalisefiltros.features[j].properties.visibility = false
    }
  }
  const content = document.getElementById((classeprincipal + comp).replace(/\s/g, ''));
  const legendFilter = content.querySelector('.legendFilter');
  const hub = content.querySelectorAll('.legendRight');

  for (const hubItem of hub) {
    const legenda = hubItem.querySelector('.legendaChart');
    const chart = hubItem.querySelector('.chartSpacing');

    if (visibility === false) {
      hubItem.style.cursor = 'not-allowed';
      chart.style.cursor = 'initial';
      legenda.style.pointerEvents = 'none';
      legendFilter.style.pointerEvents = 'none';
      legenda.querySelectorAll('.changeTextColor').forEach((element) => {
        const trocarCor = element.querySelector('.trocarCor');
        const tampa = element.querySelector('.tampa');

        element.style.color = '#929292';
        trocarCor.style.opacity = '1';
        tampa.style.opacity = '0';

        list = list.filter((item) => item !== element.textContent);
      });
    } else {
      hubItem.style.cursor = 'initial';
      legenda.style.pointerEvents = 'all';
      legendFilter.style.pointerEvents = 'all';
      legenda.querySelectorAll('.changeTextColor').forEach((element) => {
        const trocarCor = element.querySelector('.trocarCor');
        const tampa = element.querySelector('.tampa');

        element.style.color = 'rgb(90, 90, 90)';
        trocarCor.style.opacity = '0';
        tampa.style.opacity = '1';

        if (!list.includes(element.textContent)) {
          list.push(element.textContent);
        }
      });
    }
  }

  getList();

  const mode = comp === 'Mon' ? 'Monitoramento' : 'Cadastro';

  function updateFeaturesVisibility(features) {
    for (const feature of features) {
      for (const featuresAux of feature.features) {
        if (featuresAux.properties.classeprincipal === classeprincipal && featuresAux.properties.classemae === mode) {
          featuresAux.properties.visibility = visibility;
          featuresAux.properties.legend = visibility;
        }
      }
    }
  }

  updateFeaturesVisibility(FRACTUREAtiv);
  updateFeaturesVisibility(FRACTUREEnc);
  updateFeaturesVisibility(FRACTURENull);
  updateFeaturesVisibility(DATAIASELECTEDAtiv);
  updateFeaturesVisibility(DATAIASELECTEDEnc);
  updateFeaturesVisibility(DATAIASELECTEDNull);

  RefreshMap();
  const target = event.target
  target.disabled = true
  setTimeout(() => {
    target.disabled = false
  }, 1000);
}

export const AnaControl = () => {
  const target = event.target
  const visibility = target.checked
  if (event.isTrusted) {
    disableAllModes("Monitoramento")
    disableAllModes("Inventário")
    setTimeout(() => {
      auxiliar()
    }, 50);
  } else {
    auxiliar()
  }
  function auxiliar() {
    if (apianalisefiltros) {
      for (let j = 0; j < apianalisefiltros.features.length; j++) {
        apianalisefiltros.features[j].properties.visibility = visibility
      }
    }
    RefreshMap();
  }
  if (visibility) {
    const parentDiv = event.target.closest('div');

    if (parentDiv) {
      const gridElement = parentDiv.querySelector('.gridSessionsEnable');
      if (gridElement) {
        const iTag = gridElement.querySelector('i.fa-plus');
        if (iTag) {
          iTag.click()
        }
      }
    }
  }
  target.disabled = true
  setTimeout(() => {
    target.disabled = false
  }, 1000);
  return visibility;
}

function findDivsWithCategory(categoria) {
  const parentDivs = document.querySelectorAll('.toggleSessionsSub');
  const divsWithHi = [];

  parentDivs.forEach(parentDiv => {
    const childDivs = parentDiv.querySelectorAll('div');
    childDivs.forEach(childDiv => {
      if (childDiv.textContent.trim() === categoria) {
        divsWithHi.push(parentDiv);
      }
    });
  });

  const checkboxes = divsWithHi.map(div => div.querySelector('input[type="checkbox"]'));
  if (!checkboxes[0].checked) {
    checkboxes[0].click()
  }
}

export function AnalyticRequest(qualidade, buraco, recomposicao, fissura, data, categoria) {
  Loading("iniciar")
  const qualidadeStr = qualidade.length > 0 ? qualidade.map(q => q.name).join(',') : null;
  const API = predefinicoes.apianalise_defeitos
  const APIformatted = `${API[0]}${API[1]}${qualidadeStr}${API[2]}${buraco}${API[3]}${fissura}${API[4]}${recomposicao}${API[5]}${transformDate(data)}`
  // console.log('APIformatted', APIformatted)
  axios.get(APIformatted).then((response) => {
    // let fixosAux = JSON.parse(JSON.stringify(fixos));
    // function mergeData(dinamicos, fixos) {
    //   fixos.features.forEach(feature => {
    //     if (dinamicos[0]) {
    //       const match = dinamicos.find(item => item.id_trecho === feature.properties.id_trecho);
    //       if (match) {
    //         feature.properties = {
    //           ...feature.properties,
    //           ...match
    //         };
    //       }
    //     }
    //   });

    //   return fixos;
    // }
    disableAllModes("Monitoramento")
    disableAllModes("Inventário")
    console.log('response.data', response.data)
    // apianalisefiltros = mergeData(response.data, fixosAux);
    apianalisefiltros = {
      type: "FeatureCollection",
      features: jsonData.features
        .filter(feature => response.data[0].includes(feature.properties.id_trecho))
        .map(feature => ({
          ...feature,
          properties: {
            ...feature.properties,
            visibility: true
          }
        }))
    };

    // apianalisefiltros = prepareData(apianalisefiltros)
    Loading("fechar")
    findDivsWithCategory(categoria);
  })
}

function transformDate(dateStr) {
  const monthMapping = {
    'Jan': '01',
    'Fev': '02',
    'Mar': '03',
    'Abr': '04',
    'Mai': '05',
    'Jun': '06',
    'Jul': '07',
    'Ago': '08',
    'Set': '09',
    'Out': '10',
    'Nov': '11',
    'Dez': '12'
  };

  const trimmedStr = dateStr.trim();
  const [month, year] = trimmedStr.split('/');

  const monthNumber = monthMapping[month];

  if (!monthNumber) {
    throw new Error('Invalid month abbreviation provided.');
  }

  return `${monthNumber}/${year}`;
}
